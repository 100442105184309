import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@StarberryUtils";
import { Button, Navbar, Nav, Container, Row, Col } from 'react-bootstrap';
import { GetURL } from "../Common/site/functions";
import SearchOverlay from "../SearchOverlay/SearchOverlay"
// import { StaticImage } from "gatsby-plugin-image"
import { isSafari, isIOS, isMacOs } from "react-device-detect";
import $ from "jquery";

// Images
import BrandLogo from "../../images/logo.svg"
import _ from "lodash";
import './Header.scss';

import { isMobile, browserName, isTablet } from "react-device-detect";

import { containerVariants, delayMenuItems } from '../Utils/animations';
import { motion } from "framer-motion";

const myaccountURL = process.env.GATSBY_MYACCOUNT_URL || '';

const Header = (props) => {

  useEffect(() => {
    if (isSafari === true || isIOS === true || isMacOs === true) {
      $('body').addClass('iosdevicesonly');
    }
  });

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menuBurgers(sort: "Sort:ASC") {
        id
        Parent_Label
        Parent_Link {
          id
        }
        Sub_Menus {
          id
          Label
          Link {
            id
          }
          Add_Sub_Menus {
            Sub_Menu_Label
            Sub_Menu_Link {
              id
            }
            Sub_Menu_Area_Guide {
              URL
            }
            Sub_Menu_Offices {
              URL
            }

          }
        }
      }
      allMenus(sort: "Sort:ASC", where: {Show_in_Top_Navigation: true, Publish: true}) {
        URL
        Label
      }
    }
  }
`);


  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (props.fixed) {
      setScroll(window.scrollY > 60)
    }
    window.addEventListener("scroll", () => {
      if (props.fixed) {
        setScroll(window.scrollY > 60)
      }

    })
    var root = document.getElementsByTagName('html')[0];
    root.className = '';
    document.body.className = '';

  }, [])

  const menu_data = data.glstrapi.menuBurgers;
  const top_menus = data.glstrapi.allMenus;
  const [state, setState] = useState({
    menu: menu_data
  })
  const [issubmenu, setIssubmenu] = useState([])
  const [innerSubmenu, setInnerSubmenu] = useState(null)
  const [propertySearch, setPropertySearch] = useState(false)
  const [serviceMenu, setServiceMenu] = useState(false)

  const handlerClick = (item, index) => {
    setPropertySearch(false)
    setServiceMenu(false)
    if (item.Sub_Menus.length > 0) {
      let subMenu = []
      let a = state.menu.map((item, i) => {
        if (i === index) {
          if (item.isopen) {
            setIssubmenu([])
            return { ...item, isopen: false }
          }

          else {
            setIssubmenu(item.Sub_Menus)
            return { ...item, isopen: true }
          }
        }

        else {
          return { ...item, isopen: false }
        }
      })
      setState({ menu: a })
    }
    else {
      setIssubmenu([])
      let a = state.menu.map((item, i) => {
        return { ...item, isopen: false }
      })
      setState({ menu: a })
    }
  }

  const handlerSubMenuClick = (item, index) => {
    setPropertySearch(false)
    setServiceMenu(false)
    if (item.Add_Sub_Menus.length > 0) {
      if (index === innerSubmenu) {
        setInnerSubmenu(null)
      } else {
        setInnerSubmenu(index)
      }
    }
    else {
      setInnerSubmenu(null)
    }
  }

  const getSubMenuLink = (subitem) => {
    var url = '';
    if (!_.isEmpty(subitem.Sub_Menu_Link)) {
      url = GetURL(subitem.Sub_Menu_Link.id)
    } else if (!_.isEmpty(subitem.Sub_Menu_Area_Guide)) {
      url = `about/area-guides/${subitem.Sub_Menu_Area_Guide.URL}/`;
    } else if (!_.isEmpty(subitem.Sub_Menu_Offices)) {
      url = `contact/${subitem.Sub_Menu_Offices.URL}/`;
    };


    return url;
  }
  const closeBlock = () => {
    if (props.showSearch) {
      props.handlerSearchClick()
    } else {
      props.handlerClick()
    }
  }

  return (
    <>
      <header className={scroll ? "header--fixed header" : "header"} >
        <Container fluid>
          <Row>
            <Col>
              <Nav onMouseLeave={() => {
                setPropertySearch(false)
                setServiceMenu(false)
                }}>
                <Navbar expand="xl">
                  <Navbar.Brand to="/" onMouseOver={() => {
                    setPropertySearch(false)
                    setServiceMenu(false)
                  }
                  }>
                    <Link to="/">
                      {/* <picture>
                        <source media="(min-width:992px)" srcSet={BrandLogo} /> */}
                      <img className="logo mb-0" src={BrandLogo} alt="DB Roberts logo" />
                      {/* </picture> */}
                    </Link>
                  </Navbar.Brand>
                  <Navbar.Collapse className="d-none navigation-wrap"  >
                    <Nav>
                      <div className="navigation top-nav">
                        <ul>
                          <li>
                            <a href="javascript:void(0)" onMouseOver={() =>{
                              setPropertySearch(true)
                              setServiceMenu(false)
                              }}  >Property Search</a>
                            <div className={propertySearch ? "dropdown-link open" : "dropdown-link"} onMouseLeave={() => setPropertySearch(false)} >
                              <ul className="submenu searchmenu">
                                <li><Link to="/property/for-sale/">Sales</Link></li>
                                <li><Link to="/property/to-rent/">Lettings</Link></li>
                              </ul>
                            </div>
                          </li>
                          <li><Link to="/property-valuation">Book a Valuation</Link></li>
                          <li>
                            <Link to="/property-services" onMouseOver={() =>{
                               setServiceMenu(true)
                             setPropertySearch(false)
                            }}  >Services</Link>
                            <div className={serviceMenu ? "dropdown-link open" : "dropdown-link"} onMouseLeave={() => setServiceMenu(false)} >
                              <ul className="submenu servicemenu">
                                <li><Link to="/property-services/sellers/">Sellers</Link></li>
                                <li><Link to="/property-services/landlords/">Landlords</Link></li>
                                <li><Link to="/property-services/buyers/">Buyers</Link></li>
                                <li><Link to="/property-services/tenants/">Tenants</Link></li>
                                <li><Link to="/property-services/new-homes/">New Homes</Link></li>
                                <li><Link to="/property-services/auctions/">Auctions</Link></li>
                                <li><Link to="/property-services/mortgages/">Mortgages</Link></li>
                                <li><Link to="/property-services/conveyancing/">Conveyancing</Link></li>
                              </ul>
                            </div>
                          </li>
                          <li><Link to="/contact">Contact</Link></li>

                          {/* {top_menus && top_menus.map((item, i) => {
                            return (
                              <li key={i} onMouseOver={() => setPropertySearch(false)}>
                                <Link to={`/${item.URL}`}>{item.Label}</Link>
                              </li>)
                          })} */}

                        </ul>
                      </div>
                    </Nav>
                  </Navbar.Collapse>
                  <div className="nav-right ml-auto  d-flex">
                    <a onClick={props.handlerSearchClick} className="btn-circle search"><i className="icon-search-white"></i></a>
                    <a href={`${myaccountURL}/login`} className="btn-circle sb-myacc"><i className="icon-user"></i></a>
                    <Link href="tel:+4401743 284990" className="btn-circle"><i className="icon-mobile"></i></Link>
                    {(!isMobile) ?
                    <Button className="btn btn-outline btn-menu" onClick={props.handlerClick} variant="link">
                      Menu
                    </Button>
                    :
                    <a onClick={props.handlerClick} className="btn-circle btn-menu"><i className="icon-burger"></i></a>
                    }
                    <span className=" btn-close " onClick={() => { closeBlock() }}>
                      <i className="icon-close"></i>
                    </span>

                    <Button variant="link" className="btn btn-outline" onClick={props.handlerClick} className="btn btn-outline btn-account">
                      Menu
                    </Button>

                  </div>
                </Navbar>
              </Nav>
            </Col>
          </Row>
        </Container>
        {props.showSearch &&
          <motion.div
            className="search-block"
            initial="hidden"
            animate={props?.showSearch ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <SearchOverlay closeBlock={closeBlock} />
          </motion.div>
        }
        {props.showMenu &&
          <motion.div
            className="navigation-block"
            initial="hidden"
            animate={props?.showMenu ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <nav className="nav-menu">
              <ul>
                <motion.li
                  className="d-lg-none d-xl-none"
                  custom={0}
                  variants={delayMenuItems}
                ><a href="javascript:void(0)" onClick={() => setPropertySearch(!propertySearch)}>Property Search</a>
                  <i className={`${propertySearch ? 'icon-menu-minus' : 'icon-menu-plus'}`} onClick={() => setPropertySearch(!propertySearch)}></i>
                  <div className={propertySearch ? "dropdown-link open" : "dropdown-link"} onMouseLeave={() => setPropertySearch(!propertySearch)}>
                    <ul className="submenu">
                      <li><Link to="/property/for-sale">Sales</Link></li>
                      <li><Link to="/property/to-rent">Lettings</Link></li>
                    </ul>
                  </div>
                </motion.li>
                {state.menu && state.menu.map((item, i) => {
                  let url = (!_.isEmpty(item.Parent_Link)) ? GetURL(item.Parent_Link.id) : '';
                  return (
                    <motion.li
                      key={i}
                      custom={i}
                      variants={delayMenuItems}
                    >
                      {url[1] == 'Internal' ?
                        <Link to={`/${url[0]}`}>{item.Parent_Label}</Link>
                        :
                        <a href={`${url[0]}`} target="_blank">{item.Parent_Label}</a>
                      }
                      {item.Sub_Menus.length > 0 &&
                        <i className={`${item.isopen ? 'icon-menu-minus' : 'icon-menu-plus'}`} onClick={() => handlerClick(item, i)}></i>
                      }
                      <div className={item.isopen ? "dropdown-link open" : "dropdown-link"} >
                        <ul className="submenu">
                          {
                            item.Sub_Menus &&
                            item.Sub_Menus.map((newitem, s) => {
                              let sub_url = (!_.isEmpty(newitem.Link)) ? GetURL(newitem.Link.id) : '';
                              return (
                                <li key={s}>
                                  {sub_url[1] == 'Internal' ?
                                    <Link to={`/${sub_url[0]}`}>{newitem.Label}</Link> :
                                    <a href={`${sub_url[0]}`} target="_blank">{newitem.Label}</a>
                                  }
                                  {newitem.Add_Sub_Menus.length > 0 &&
                                    <>
                                      <i className={` ${s === innerSubmenu ? 'icon-menu-minus' : 'icon-menu-plus'}`} onClick={() => { handlerSubMenuClick(newitem, s) }}></i>

                                      <div className={s === innerSubmenu ? "" : "dropdown-link"} >
                                        <ul className="inner-submenu">
                                          {
                                            newitem.Add_Sub_Menus &&
                                            newitem.Add_Sub_Menus.map((subitem, s) => {
                                              let sub_url = getSubMenuLink(subitem);
                                              return (
                                                <li key={s}>
                                                  <Link to={`/${sub_url}`}>{subitem.Sub_Menu_Label}</Link>
                                                </li>
                                              )
                                            }
                                            )
                                          }
                                        </ul>
                                      </div>
                                    </>
                                  }
                                </li>
                              )
                            }
                            )
                          }
                        </ul>
                      </div>
                    </motion.li>
                  )
                })}
              </ul>
            </nav>
          </motion.div>
        }
      </header>
    </>
  )
}



export default Header
