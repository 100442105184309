
// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
    MARKET_YOUR_PROPERTY: {
      name: 'Market Your Property',
      alias: '/market-your-property'
    },
    ABOUT: {
      name: 'About',
      alias: '/about'
    },
    CONTACT: { // Our Office
      name: 'Contact',
      alias: '/contact'
    },
    MEET_THE_TEAM: {
      name: 'Our People',
      alias: '/contact/our-team',
    },
    CAREERS: {
      name: 'Careers',
      alias: '/about/careers',
    },
    JOB_LISTING: {
      name: 'Jobs',
      alias: '/about/careers/job-listing',
    },
    LATEST_NEWS: {
      name: 'News',
      alias: '/news',
    },
    MAGAZINES: {
      name: 'Magazines',
      alias: '/magazines',
    },
    VIDEOS: {
      name: 'Videos',
      alias: '/videos',
    },
    VIDEO: {
      name: 'Video',
      alias: '/video',
    },
    CAREER_VIDEOS: {
      name: 'Career Videos',
      alias: '/about/careers/career-videos',
    },
    AREA_GUIDES: {
      name: 'Area Guides',
      alias: '/about/area-guides'
    },
    PROPERTY_SERVICES: { // What We Do
      name: 'Property Services',
      alias: '/property-services',
      name2: 'Our Property Services'
    },
    PROPERTY_SERVICES_BUY: {
      name: 'Buy',
      alias: '/property-services/buyer'
    },
    PROPERTY_SERVICES_SELL: {
      name: 'Sell',
      alias: '/property-services/seller'
    },
    PROPERTY_SERVICES_LANDORD: {
      name: 'Sell',
      alias: '/property-services/landlord'
    },
    PROPERTY_SERVICES_TENANT: {
      name: 'Sell',
      alias: '/property-services/tenant'
    },
    LOCAL_RECOMMENDATION: {
      name: 'Local Recommendation',
      alias: '/about/local-recommendation'
    },
    BOOK_A_VALUATION: {
      name: 'Book a Valuation',
      alias: '/property-valuation/home-visit-valuation'
    },
    
}


export const MARKET_YOUR_PROPERTY_PAGE_URL = URLS.MARKET_YOUR_PROPERTY;
export const ABOUT_PAGE_URL = URLS.ABOUT;
export const CONTACT_PAGE_URL = URLS.CONTACT;
export const MEET_THE_TEAM_PAGE_URL = URLS.MEET_THE_TEAM;
export const CAREERS_PAGE_URL = URLS.CAREERS;
export const JOB_LISTING_PAGE_URL = URLS.JOB_LISTING;
export const LATEST_NEWS_PAGE_URL = URLS.LATEST_NEWS;
export const MAGAZINE_PAGE_URL = URLS.MAGAZINES;
export const VIDEOS_PAGE_URL = URLS.VIDEOS;
export const VIDEO_DETAIL_PAGE_URL = URLS.VIDEO;
export const CAREER_VIDEOS_PAGE_URL = URLS.CAREER_VIDEOS;
export const AREA_GUIDES_PAGE_URL = URLS.AREA_GUIDES;
export const PROPERTY_SERVICES_PAGE_URL = URLS.PROPERTY_SERVICES;
export const PROPERTY_SERVICES_BUY_PAGE_URL = URLS.PROPERTY_SERVICES_BUY;
export const PROPERTY_SERVICES_SELL_PAGE_URL = URLS.PROPERTY_SERVICES_SELL;
export const PROPERTY_SERVICES_LANDORD_PAGE_URL = URLS.PROPERTY_SERVICES_LANDORD;
export const PPROPERTY_SERVICES_TENANT_PAGE_URL = URLS.PROPERTY_SERVICES_TENANT;
export const LOCAL_RECOMMENDATION_PAGE_URL = URLS.LOCAL_RECOMMENDATION;
export const BOOK_A_VALUATION_PAGE_URL = URLS.BOOK_A_VALUATION;